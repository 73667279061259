/* google cannot be detected */
/* eslint no-undef: 0 */
import Vue from "vue"
import { getV2UrlByCategoryId } from "~/services/public/Listing"
import { CATEGORY_V4_ID_HOUSE_MOVE } from "~/constants/category"
import { V2_FLOORS, V2_PROPERTY_TYPES } from "~/constants/listing"

export function isAnExcludedIsland(payload: ListingPayload) {
  // provided by nuxt-google-maps-module
  const google: any = (Vue as any).$google
  if (!google) {
    return false
  }

  let excluded = false

  const islands = [
    new google.maps.LatLngBounds(
      new google.maps.LatLng(58.6689164398, -3.8548798561),
      new google.maps.LatLng(61.1059423887, -0.2080261707)
    ), // orkney and shetland islands
    new google.maps.LatLngBounds(
      new google.maps.LatLng(56.8277778684, -7.7497896127),
      new google.maps.LatLng(57.7648395509, -7.0137056283)
    ), // southern outer hebrides
    new google.maps.LatLngBounds(
      new google.maps.LatLng(57.7296620943, -7.3020967416),
      new google.maps.LatLng(58.5215184636, -6.0908540658)
    ), // northern outer hebrides
    new google.maps.LatLngBounds(
      new google.maps.LatLng(50.56386, -1.587506),
      new google.maps.LatLng(50.711707, -1.03613)
    ), // isle of wight box 1
    new google.maps.LatLngBounds(
      new google.maps.LatLng(50.714533, -1.477729),
      new google.maps.LatLng(50.750497, -1.092435)
    ), // isle of wight box 2
    new google.maps.LatLngBounds(
      new google.maps.LatLng(50.750497, -1.346923),
      new google.maps.LatLng(50.773299, -1.243583)
    ) // isle of wight box 3
  ]

  for (let i = 0; i < islands.length; i++) {
    if (
      (payload.pickup_address_data?.latitude &&
        payload.pickup_address_data?.longitude &&
        islands[i].contains(
          new google.maps.LatLng(
            payload.pickup_address_data?.latitude,
            payload.pickup_address_data?.longitude
          )
        )) ||
      (payload.delivery_address_data?.latitude &&
        payload.delivery_address_data?.longitude &&
        islands[i].contains(
          new google.maps.LatLng(
            payload.delivery_address_data?.latitude,
            payload.delivery_address_data?.longitude
          )
        ))
    ) {
      excluded = true
    }
  }

  return excluded
}

export function shouldRedirectToV2(payload: ListingPayload) {
  return (
    (payload.pickup_address_data?.ISO_3166_1_Alpha_2 &&
      payload.pickup_address_data?.ISO_3166_1_Alpha_2 !== "GB") ||
    (payload.delivery_address_data?.ISO_3166_1_Alpha_2 &&
      payload.delivery_address_data?.ISO_3166_1_Alpha_2 !== "GB") ||
    isAnExcludedIsland(payload)
  )
}

export function redirectToV2(payload: ListingPayload, v4Host: string = "") {
  const form = document.createElement("form")
  const inputFactory = ([name, value]) => {
    const el = document.createElement("input")
    el.setAttribute("type", "hidden")
    el.setAttribute("name", name)
    el.setAttribute("value", value || "")
    form.appendChild(el)
  }

  form.method = "POST"
  // Comment & Uncomment for local execution
  form.action = "/" + getV2UrlByCategoryId(payload.category_id)
  // form.action = v4Host + "/" + getV2UrlByCategoryId(payload.category_id)

  const attrs: [string, string | null][] = [
    ["form", payload.from || ""],
    ["to", payload.to || ""],
    ["category_id", String(payload.category_id)],
    ["p_latitude", String(payload.pickup_address_data?.latitude)],
    ["p_longitude", String(payload.pickup_address_data?.longitude)],
    ["d_latitude", String(payload.delivery_address_data?.latitude)],
    ["d_longitude", String(payload.delivery_address_data?.longitude)],
    ["p_formatted_address", String(payload.from)],
    ["d_formatted_address", String(payload.to)],
    ["quote_from", String(payload.from)],
    ["quote_to", String(payload.to)],
    ["force_send_goods", "1"]
  ]
  if (payload.category_id === CATEGORY_V4_ID_HOUSE_MOVE) {
    attrs.push(
      ["origin_type_of_property", V2_PROPERTY_TYPES[String(payload.origin_type_of_property)]],
      [
        "destination_type_of_property",
        V2_PROPERTY_TYPES[String(payload.destination_type_of_property)]
      ],
      ["origin_property_level", V2_FLOORS[String(payload.origin_property_level)]],
      ["destination_property_level", V2_FLOORS[String(payload.destination_property_level)]]
    )
  }
  attrs.forEach(inputFactory)
  document.body.appendChild(form)
  form.submit()
}
